import React from "react";
import Img from "gatsby-image";
import Box from ".";
import styled from "styled-components";

export default function ImageBox({ fluid, fixed, ...rest }) {
  return (
    <ImageBoxWrap {...rest}>
      <Img fluid={fluid} fixed={fixed} />
    </ImageBoxWrap>
  );
}

const ImageBoxWrap = styled(Box)`
  position: relative;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.25rem;

    &:before {
      content: "";
      border-radius: 0.25rem;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.025);
      z-index: 10;
    }
  }
`;
